// export const API_BASE_URL = "http://api.dev-tripcater.com/api/v1/"; // Dev
export const API_BASE_URL = "https://api-demo.tripcater.com/api/v1/"; // Stage
 //export const API_BASE_URL = "https://api.tripcater.com/api/v1/"; // PROD

export const endpoints = {
  login: "auth/login",
  sso: "sso/auth/login",
  logout: "logout",
  trips: {
    get: "trips",
    count: "trips/total-count",
    delete: "trip/delete",
    getByID: "trip",
    update: "trip/update",
    changeRequest: "trip/change-request/new",
    proceed: "trip/proceed",
  },
  password: {
    forget: "password/forget",
    change: "password/change",
  },
  location: {
    search: "location/search",
  },
  corporate: {
    travelPolicies: {
      get: "corporate/travel-policies",
      create: "corporate/travel-policies/new",
      search: "corporate/travel-policies/search",
      delete: "corporate/travel-policies/delete",
      edit: "corporate/travel-policies/edit",
      assign: "corporate/travel-policy/assign",
      unassign: "corporate/travel-policy/unassign",
    },
    approvalProcess: {
      get: "corporate/approval-processes",
      create: "corporate/approval-process/new",
      edit: "corporate/approval-process/edit",
      getByID: "corporate/approval-process",
      delete: "corporate/approval-process/delete",
    },
  },
  traveler: {
    list: "travelers",
    get: "traveler",
    search: "traveler/search",
    delete: "traveler/delete",
    deactivate: "traveler/deactivate",
    activate: "traveler/activate",
    invite: "traveler/invite",
    edit: "traveler/personal-info/edit",
    new: "traveler/new",
  },
  reporting: {
    activity: "reporting/activity-overview",
    download: "reporting/report-download",
  },
  flight: {
    search: "home/flights/search",
    filter: "home/flights/search/filter",
    getByID: "flight/itinerary/details",
  },
  checkout: {
    selectService: "checkout/select-service",
    traveler: {
      get: "checkout/travelers",
      update: "checkout/traveler/info/add",
    },
    order: {
      summary: "checkout/order/summary",
      booked: "checkout/order/booking/confirm",
    },
    approval: "checkout/approval",
  },
  loyaltyPrograms: "loyalty-programs",
  user: "user/me",
  airline: {
    search: "airline/search",
  },
};

export const ERROR_MESSAGE: string =
  "Something went wrong! Please try again after sometime.";

export const SUCCESS_MESSAGE: string = "Request Successful";

export const SERVICE_ERROR_MESSAGE: string =
  "Service didn't return correct response";

export const SERVICE_FAILED_MESSAGE: string = "Service failed:";
